@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@100;200;300;400;500&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-family: "Quicksand", sans-serif;
  height: 100vh;
}
body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
body[data-theme="light"] {
  --color-primary: rgb(14, 3, 24);
  --color-background: rgb(38, 9, 65);
  --color-smallback: rgba(245, 190, 245, 0.97);
  --color-accent: #ffe96f;
  --color-heading: rgb(20, 3, 36);
  --color-white: white;
  --color-icon: transparent;
  --color-sm-heading: white;
}
body[data-theme="dark"] {
  --color-primary: aliceblue;
  --color-background: rgb(51, 51, 51);
  --color-smallback: rgba(33, 33, 33);
  --color-accent: rgb(96, 212, 255);
  --color-heading: rgb(95, 212, 255);
  --color-white: white;
  --color-icon: white;
  --color-sm-heading: rgb(95, 212, 255);
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
header {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  position: relative;
  width: 100%;
}

main {
  display: flex;
  flex-direction: column;
  font-weight: 100;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 60vh;
  width: 100%;
}

p {
  font-weight: 500;
}
h1 {
  font-weight: 200;
}
h3 {
  font-weight: 600;
}
.my-name {
  letter-spacing: 2px;
  margin-top: 2rem;
  margin-bottom: 10px;
  text-align: center;
  width: 60%;
}
.my-name h1 {
  color: var(--color-white);
  font-size: 60px;
}
.my-name h1:hover {
  animation: name-bounce 1s ease;
}
@keyframes name-bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}
.roles-container {
  height: fit-content;
  width: 100%;
}

.roles {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 200;
  text-align: center;
  height: 50px;
  position: absolute;
  width: 100%;
}
.roles span {
  color: var(--color-accent);
  font-size: 22px;
  font-weight: 200;
  margin: 0;
  position: absolute;
}
.role-1 {
  opacity: 1;
}

.role-2,
.role-3,
.role-4,
.role-5 {
  opacity: 0;
}

@keyframes hideshow {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.role-1 {
  animation: hideshow 8s ease infinite;
}
.role-2 {
  animation: hideshow 8s 2s ease infinite;
}
.role-3 {
  animation: hideshow 8s 4s ease infinite;
}
.role-4 {
  animation: hideshow 8s 6s ease infinite;
}
.role-5 {
  animation: hideshow 8s 7s infinte;
}

.social-links {
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  margin-top: 1rem;
  max-width: 70%;
}
.social .mail-icon {
  transform: scale(1.2);
}

.social ion-icon {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  justify-content: center;
  padding: 1rem 0.7rem 0.2rem;
  color: var(--color-white);
}
.social a,
.cv-logo {
  color: var(--color-white);
  display: inline-block;
  transition: transform 0.2s;
}
.social a:hover,
.social a:focus,
.cv-logo:hover,
.cv-logo:focus {
  transform: scale(1.3);
}

@media screen and (min-width: 500px) {
  .roles {
    font-size: 15px;
  }
}
/* Menu Icons */
.menu {
  background-color: var(--color-background);
  border: 1px solid var(--color-white);
  border-radius: 5px;
  color: var(--color-white);
  display: none;
  padding: 10px 15px;
  position: absolute;
  right: 15px;
  top: 0;
  /* z-index: 30; */
}
.menu p {
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1.5px;
  margin: 16px 0;
}
.menu p:hover {
  border-bottom: 1px solid white;
  font-weight: 500;
}
.close-menu {
  display: none;
}
.open-menu {
  display: block;
}
.hide-menu {
  cursor: pointer;
  font-size: 15px;
  padding: 0 4px;
  margin: 0 0 10px;
}
.menu-box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 25px 0 0;
  height: 40px;
  width: 28px;
}

.line {
  border-top: 0.5px solid var(--color-white);
  background-color: var(--color-white);
  margin-bottom: 6px;
  height: 2.2px;
  width: 100%;
}

.line-3 {
  animation: play 4s ease 1s infinite;
}
@keyframes play {
  10% {
    align-self: flex-end;
    width: 50%;
  }
  40% {
    width: 100%;
  }
  70% {
    align-self: flex-start;
    width: 50%;
  }
  100% {
    align-self: flex-start;
    width: 50%;
  }
}

.arrow {
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 200;
  position: absolute;
  left: 0;
  top: 50%;
  height: 30px;
  width: 99%;
}
.arrow .left,
.arrow .right {
  /* background-color: var(--color-white); */
  font-size: 17px;
  padding: 0 5px;
}
.open-port-btn,
.open-skills-btn {
  cursor: pointer;
  /* transform: scale(0.6); */
}
.open-port-btn {
  margin-left: 6px;
}
.about-summary {
  line-height: 24px;
  margin-top: 15px;
}
.about-page h2 {
  color: var(--color-heading);
  border-bottom: 1px solid var(--color-heading);
  font-size: 35px;
  font-weight: 200;
  padding-bottom: 4px;
}
@keyframes giggle-right {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes giggle-left {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-15px);
  }
  100% {
    transform: translateX(0);
  }
}
.arrow .left {
  animation: giggle-right 2s 1s infinite;
}
.arrow .right {
  animation: giggle-left 2s 1s infinite;
}
.hide-port {
  transform: translateX(-300px);
  cursor: pointer;
}
.hide-skills {
  cursor: pointer;
  transform: translateX(300px);
  display: none;
}
.portfolio,
.skills {
  color: var(--color-primary);
  background-color: var(--color-smallback);
  /* margin-bottom: 50px; */
  padding: 10px 10px 15px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 251px;
}
.portfolio {
  padding-left: 10px;
  width: 284px;
}
.portfolio,
.skills,
.about-page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.portfolio::-webkit-scrollbar,
.skills::-webkit-scrollbar,
.about-page::-webkit-scrollbar {
  display: none;
}

.port-box {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  left: 0;
  top: 30%;
  position: absolute;
  height: 70vh;
  border-radius: 5px;
  transition: transform 0.7s;
  width: 285px;
  z-index: 1000;
}
.skills-container {
  display: flex;
  overflow: hidden;
  right: 0;
  top: 30%;
  position: absolute;
  height: 70%;
  border-radius: 5px;
  transition: transform 0.7s;
  /* z-index: 2; */
}
@media screen and (min-width: 750px) {
  .port-box,
  .skills-container {
    top: 10%;
    height: 90%;
  }
}

.close-left,
.close-right {
  /* border: 1px solid rgba(245, 190, 245, 0.863); */
  color: purple;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  height: 25px;
  padding: 0 2px;
  position: absolute;
  right: 3px;
  top: 56.5%;
}
.close-left {
  left: 3px;
}
.close-left:hover,
.close-right:hover {
  color: var(--color-primary);
  font-size: 22px;
}
.folder,
.skill {
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* font-weight: 400; */
  align-items: center;
  margin: 0 0 20px;
  width: 95%;
}
.folder {
  margin-left: 12px;
}
.skill {
  margin-bottom: 35px;
  padding: 15px 0;
  /* border: none; */
}
.icon {
  background-color: var(--color-icon);
  margin: 20px 0 15px;
  /* transform: scale(1.3); */
}
body[data-theme="dark"] + .icon {
  background-color: var(--color-white);
}
/* .icon-3,
.icon-4 {
  margin-top: 2px;
} */
.portfolio h2,
.skills h2 {
  color: var(--color-heading);
  font-weight: 400;
  margin: 2px 0 20px;
  text-align: center;
}
.folder .text {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
  padding: 0 15px;
}
.skills .text {
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
}
.folder h3,
.skills h3 {
  font-size: 17px;
  margin-bottom: 10px;
  text-align: center;
}
.cv-page {
  position: absolute;
  left: 15%;
  height: 98vh;
  overflow: hidden;
  width: 70vw;
  top: 0;
  transition: 1s;
  transform: translateY(-150%);
  z-index: 1500;
}

.cv-frame {
  position: relative;
  overflow: hidden;
  padding-top: 141.4%;
  width: 100%;
}
.cv-page h2 {
  color: var(--color-sm-heading);
  border-bottom: 1px solid var(--color-sm-heading);
  font-size: 36px;
  font-weight: 200;
  padding: 10px 0;
  margin: 5px 0 30px;
}
.cv {
  position: absolute;
  border: 0;
  height: 80vh;
  left: 0;
  top: 0;
  width: 100%;
}
.close-cv {
  color: var(--color-sm-heading);
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  right: 10px;
  top: 30px;
}
@media screen and (max-width: 850px) {
  main {
    justify-content: flex-start;
    padding-top: 4rem;
  }
  .social-links {
    position: relative;
    top: 3rem;
  }

  .cv {
    width: 100%;
  }
}

.about-page {
  background-color: var(--color-smallback);
  border-radius: 6px;
  color: var(--color-primary);
  max-height: 94%;
  max-width: 80vw;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 10%;
  padding: 10px 20px;
  position: absolute;
  top: 5%;
}
.close-btn {
  /* border: 1px solid rgb(14, 3, 24); */
  color: var(--color-heading);
  cursor: pointer;
  font-size: 20px;
  padding: 0 4px;
  position: absolute;
  top: 20px;
  right: 15px;
}
.hide-about {
  display: none;
  z-index: -5;
}
@media screen and (min-width: 415px) {
  .about-page {
    max-width: 300px;
  }
  .roles span {
    font-size: 27px;
  }
  .port-box,
  .skills-container {
    top: 0;
    height: 100%;
  }
}

.footer {
  bottom: 6px;
  font-size: 11px;
  margin-bottom: 5px;
  opacity: 0.4;
  position: absolute;
  text-align: center;
}
.footer a {
  color: var(--color-white);
  text-decoration: none;
}
.footer:hover {
  color: var(--color-white);
  cursor: pointer;
  opacity: 1;
}

@media screen and (max-width: 415px) {
  body {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
  .my-name h1 {
    font-size: 40px;
  }
  .roles span {
    font-size: 18px;
  }
  .social-links {
    width: 90%;
    max-width: 100%;
  }
  .social ion-icon {
    padding: 1rem 0.3rem 0.2rem;
    transform: scale(0.7);
  }
  .arrow {
    top: 60%;
  }
}

.theme-switcher {
  color: white;
  position: absolute;
  left: 10px;
  top: 0;
  z-index: 3000;
}
input {
  height: 0;
  width: 0;
  visibility: hidden;
}
label {
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 50px;
  display: block;
  height: 17px;
  width: 40px;
  text-indent: -99999px;
  position: relative;
  transition: 0.5s ease background-color;
}
label::after {
  position: absolute;
  border-radius: 50px;
  background-color: var(--color-background);
  content: "";
  left: 3px;
  top: 50%;
  height: 12px;
  transform: translateY(-50%);
  transition: 0.5s ease;
  width: 12px;
}
input:checked + label {
  background-color: var(--color-background);
  border: 2px solid var(--color-white);
}
input:checked + label::after {
  background-color: var(--color-white);
  left: calc(100% - 14px);
}
